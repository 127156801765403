/* eslint-disable */
define(['zepto',
    'componentsCore',
    'prop-types',
    'lodash',
    'reactDOM',
    'santa-components',
    'warmupUtils',
    'tpaComponents/common/tpaMetaData',
    'tpaComponents/bi/errors',
    'tpaComponents/services/clientSpecMapService',
    'tpaComponents/utils/tpaUtils',
    'tpaComponents/bi/events',
    'tpaComponents/mixins/tpaScrollMixin'

],
function ($,
          componentsCore,
          PropTypes,
          _,
          ReactDOM,
          santaComponents,
          warmupUtils,
          tpaMetaData,
          tpaErrors,
          clientSpecMapService,
          tpaUtils,
          events,
          tpaScrollMixin
) {
    'use strict';
    /* eslint-enable */

    const {santaTypesDefinitions} = santaComponents;
    const {tpaWarmup} = warmupUtils;

    const DENY_IFRAME_RENDERING_STATES = {
        mobile: 'unavailableInMobile',
        https: 'unavailableInHttps'
    };

    const OVERLAY_STATES = {
        notInClientSpecMap: 'notInClientSpecMap',
        unresponsive: 'unresponsive',
        preloader: 'preloader'
    };

    const onKeyEvent = function (event, eventType) {
        const params = {charCode: event.charCode, keyCode: event.keyCode};
        this.sendPostMessage({
            intent: 'addEventListener',
            eventType,
            params
        });
    };

    const shouldHandleEvent = function (props) {
        return !props.rootId || _.includes(props.getRootIdsWhichShouldBeRendered(), props.rootId);
    };

    const isFullScreenAppOnMobile = function (props) {
        const clientSpecMapEntry = props.getClientSpecMapEntry(props.compData.applicationId);
        return _.includes(tpaMetaData.PERMITTED_FULL_SCREEN_TPAS_IN_MOBILE, _.get(clientSpecMapEntry, 'appDefinitionId'));
    };

    /**
     * @class tpa.mixins.tpaCompBase
     */
    return {
        propTypes: {
            pageStub: santaTypesDefinitions.Component.pageStub,
            isMobileView: santaComponents.santaTypesDefinitions.isMobileView,
            isViewerMode: santaComponents.santaTypesDefinitions.isViewerMode,
            currentUrl: santaComponents.santaTypesDefinitions.currentUrl.isRequired,
            compData: santaComponents.santaTypesDefinitions.Component.compData.isRequired,
            style: santaComponents.santaTypesDefinitions.Component.style.isRequired,
            id: santaComponents.santaTypesDefinitions.Component.id.isRequired,
            isClientAfterSSR: santaComponents.santaTypesDefinitions.isClientAfterSSR,
            isFirstRenderAfterSSR: santaComponents.santaTypesDefinitions.isFirstRenderAfterSSR,
            getMemberDetails: santaComponents.santaTypesDefinitions.SiteMembersSantaTypes.getMemberDetails.isRequired,
            windowResizeEvent: santaComponents.santaTypesDefinitions.SiteAspects.windowResizeEvent.isRequired,
            reportBI: santaComponents.santaTypesDefinitions.reportBI.isRequired,
            biData: santaComponents.santaTypesDefinitions.biData.isRequired,
            compProp: santaComponents.santaTypesDefinitions.Component.compProp,
            primaryPageId: santaComponents.santaTypesDefinitions.primaryPageId.isRequired,
            getRootIdsWhichShouldBeRendered: santaComponents.santaTypesDefinitions.getRootIdsWhichShouldBeRendered,
            reportBeatEvent: santaComponents.santaTypesDefinitions.reportBeatEvent.isRequired,
            santaBase: santaComponents.santaTypesDefinitions.santaBase.isRequired,
            getClientSpecMapEntry: santaComponents.santaTypesDefinitions.getClientSpecMapEntry.isRequired,
            dynamicClientSpecMapAspect: santaComponents.santaTypesDefinitions.SiteAspects.dynamicClientSpecMapAspect.isRequired,
            clientSpecMap: santaComponents.santaTypesDefinitions.RendererModel.clientSpecMap.isRequired,
            languageCode: santaComponents.santaTypesDefinitions.RendererModel.languageCode,
            pageId: santaComponents.santaTypesDefinitions.Component.pageId,
            rootId: santaComponents.santaTypesDefinitions.Component.rootId,
            structure: santaComponents.santaTypesDefinitions.Component.structure,
            tpaComponentsDomAspect: santaComponents.santaTypesDefinitions.SiteAspects.tpaComponentsDomAspect.isRequired,
            isHorizontallyDocked: santaTypesDefinitions.Component.isHorizontallyDocked,
            isTpaRenderedInSsr: santaComponents.santaTypesDefinitions.isTpaRenderedInSsr,
            isExperimentOpen: santaComponents.santaTypesDefinitions.isExperimentOpen,
            getExistingRootNavigationInfo: santaComponents.santaTypesDefinitions.getExistingRootNavigationInfo.isRequired,
            currentUrlPageId: santaComponents.santaTypesDefinitions.Component.currentUrlPageId.isRequired,
            shouldRenderTPAsIframe: santaComponents.santaTypesDefinitions.RenderFlags.shouldRenderTPAsIframe.isRequired
        },

        getDefaultProps() {
            return {
                shouldRenderTPAsIframe: true
            };
        },

        mixins: [componentsCore.mixins.createChildComponentMixin, tpaScrollMixin],

        ALIVE_TIMEOUT: 20000,
        OVERLAY_GRACE: 5000,

        statics: {
            compSpecificIsDomOnlyOverride() {
                return false;
            }
        },

        getCompMinHeight(props) {
            if (!this.isUnderMobileView()) {
                return;
            }
            const clientSpecMapEntry = props.getClientSpecMapEntry(props.compData.applicationId);
            const widgetId = this.getWidgetId();
            const minHeightInMobile = _.get(clientSpecMapEntry, `widgets.${widgetId}.componentFields.minHeightInMobile`);
            if (minHeightInMobile && _.isNumber(minHeightInMobile)) {
                return minHeightInMobile;
            }
        },

        onKeyDown(event) {
            if (shouldHandleEvent(this.props)) {
                onKeyEvent.call(this, event, 'KEY_DOWN');
            }
        },

        onKeyUp(event) {
            if (shouldHandleEvent(this.props)) {
                onKeyEvent.call(this, event, 'KEY_UP');
            }
        },

        isUnderMobileView() {
            return this.props.isMobileView;
        },

        getEcomParams() {
            const appData = this.getAppData();
            return appData.appDefinitionId === '1380b703-ce81-ff05-f115-39571d94dfcd' &&
                this.props.currentUrl.query['ecom-tpa-params'];
        },

        _createOverlayChildComponent(type, overlay, skinExport, props) {
            props = props || {};
            return this.createChildComponent(
                _.merge(props, {
                    id: skinExport,
                    style: this.props.style,
                    overlay,
                    applicationId: this.props.compData.applicationId,
                    hideOverlayFunc: this._hideOverlay,
                    reloadFunc: this._reload,
                    compData: this.props.compData,
                    languageCode: this.props.languageCode,
                    santaBase: this.props.santaBase,
                    getClientSpecMapEntry: this.props.getClientSpecMapEntry
                }),
                type,
                skinExport,
                {}
            );
        },

        _hideOverlay() {
            this.setState({showOverlay: false});
        },

        _getUnresponsiveText() {
            if (this.isUnderMobileView()) {
                return 'Can\'t see the content? ';
            }
            return 'To view this content, click ';
        },

        _getReloadext() {
            if (this.isUnderMobileView()) {
                return 'Tap to reload.';
            }
            return 'reload.';
        },

        _createOverlay(overlayState) { // eslint-disable-line complexity
            if (!overlayState) {
                return null;
            }
            switch (overlayState) {
                case OVERLAY_STATES.preloader:
                    return this._createOverlayChildComponent(
                        'wysiwyg.viewer.components.tpapps.TPAPreloaderOverlay',
                        overlayState,
                        'preloaderOverlay'
                    );
                case OVERLAY_STATES.unresponsive:
                    const props = {
                        text: this._getUnresponsiveText(),
                        reload: this._getReloadext()
                    };
                    return this._createOverlayChildComponent(
                        'wysiwyg.viewer.components.tpapps.TPAUnavailableMessageOverlay',
                        overlayState,
                        'unavailableMessageOverlay',
                        props
                    );
                case DENY_IFRAME_RENDERING_STATES.https:
                    return this._createOverlayChildComponent(
                        'wysiwyg.viewer.components.tpapps.TPAUnavailableMessageOverlay',
                        overlayState,
                        'unavailableMessageOverlay',
                        {text: 'We\'re sorry, this content cannot be displayed.'}
                    );
                case DENY_IFRAME_RENDERING_STATES.mobile:
                    return this._createOverlayChildComponent(
                        'wysiwyg.viewer.components.tpapps.TPAUnavailableMessageOverlay',
                        overlayState,
                        'unavailableMessageOverlay',
                        {text: 'We\'re sorry, this content is currently not optimized for mobile view.'}
                    );
                case OVERLAY_STATES.notInClientSpecMap:
                    return this._createOverlayChildComponent(
                        'wysiwyg.viewer.components.tpapps.TPAUnavailableMessageOverlay',
                        overlayState,
                        'unavailableMessageOverlay'
                    );
                default:
                    return null;
            }
        },

        _getWidgetName() {
            const widgetId = _.get(this.props.compData, 'widgetId');
            const appData = this.getAppData();
            const widgetData = _.get(appData.widgets, widgetId);

            return _.get(widgetData, 'appPage.name') || appData.appDefinitionName;
        },

        getCompHeight() {
            const {style} = this.props;
            if (this.state.ignoreAnchors) {
                return style.height;
            }
            const isZeroHeightAllowed = _.result(this, 'isZeroHeightAllowed', false);
            const height = !_.isUndefined(this.state.height) ? this.state.height : style.height;
            if (height === 0 && !isZeroHeightAllowed) {
                return style.height;
            }
            return height;
        },

        getCompWidth() {
            const {style} = this.props;
            return !_.isUndefined(this.state.width) ? this.state.width : style.width;
        },

        getCommonStyle() {
            const minHeight = this.getCompMinHeight(this.props);
            const height = this.getCompHeight();
            const width = this.getCompWidth();
            return {
                width,
                height,
                minHeight: minHeight ? Math.max(minHeight, height) : height,
                minWidth: width
            };
        },

        getCompRootStyle(style) { // eslint-disable-line complexity
            style = _.clone(style);
            if (this.isUnderMobileView()) {
                if (this.state.isMobileFullScreenMode) {
                    return {
                        display: 'block'
                    };
                }
                if (isFullScreenAppOnMobile(this.props)) {
                    return {
                        display: 'none'
                    };
                }
            }

            if (this.state && (this.state.isAlive ||
                this.state.overlay === OVERLAY_STATES.preloader ||
                this.state.overlay === OVERLAY_STATES.unresponsive ||
                this.state.overlay === DENY_IFRAME_RENDERING_STATES.mobile)) {
                this.state.visibility = 'visible';
            }

            style = _.assign(style, {
                overflow: this.state.ignoreAnchors ? 'visible' : 'hidden',
                visibility: this.state.visibility === 'hidden' ? 'hidden' : ''
            });
            style = _.assign({}, style, this.getCommonStyle());

            if (this.props.isHorizontallyDocked) {
                style = _.omit(style, ['width', 'minWidth']);
            }
            //  Z-Index was added to support 2G (new) eCom cart widget on sticky headers, but we don't want to set z-index on components.( https://jira.wixpress.com/browse/TPA-2123 )
            //  SINCE MANY SITES WERE SAVED WITH THE WIDGET *ON TOP* OF THE HEADER (NOT INSIDE)
            //  IT MAY DISAPPEAR UNDER IT IF IT'S FIXED POSITIONED AND  WE MIGHT NEED TO BRING IT BACK AND FIND A BETTER SOLUTION
            //
            //if (this.props.rootId === 'masterPage' && !this.isUnderMobileView()) {
            //    style.zIndex = '100';
            //}

            return style;
        },

        shouldRenderIframe() {
            if (this.props.shouldRenderTPAsIframe === false) {
                return false;
            }
            const overlay = this.state.overlay;
            const overlayDoesNotContaineDenyState = !_.includes(DENY_IFRAME_RENDERING_STATES, overlay);
            const appIsNotResponsive = this.state.overlay === OVERLAY_STATES.unresponsive;
            const shouldRenderIframe = overlayDoesNotContaineDenyState && !appIsNotResponsive && this.isCompInClientSpecMap();

            return shouldRenderIframe;
        },

        allowScrolling() {
            const isFullPage = clientSpecMapService.isFullPage(this.props.compData, this.getAppData());
            const isFullPageInDesktop = clientSpecMapService.isFullPageDesktopOnly(this.props.compData, this.getAppData()) && !this.props.isMobileView;
            return isFullPage || isFullPageInDesktop ? 'yes' : 'no';
        },

        getIframeDimensions() {
            if (this.state.isMobileFullScreenMode) {
                return {
                    width: '100%',
                    height: '100%'
                };
            }
            const size = {};
            if (this.props.isHorizontallyDocked) {
                size.width = '100%';
            }
            if (this.state.height && this.state.ignoreAnchors) {
                size.height = this.state.height;
            }
            return size;
        },

        shouldRenderIframeWithSrc() {
            return tpaUtils.isTPASection(this) && this.isTPASupportedInSSRWarmup();
        },

        _getIframeProperties() { // eslint-disable-line complexity
            if (!this.shouldRenderIframe()) {
                return null;
            }

            const shouldShowIframe = this._shouldShowIframe(this.state);
            const iframeSrc = this.buildUrl(this.getBaseUrl());
            const widgetName = this._getWidgetName();

            const iframeProperties = {
                'data-src': iframeSrc,
                key: iframeSrc,
                scrolling: this.allowScrolling(),
                frameBorder: '0',
                allow: tpaUtils.getIFramePermissions(),
                allowtransparency: 'true',
                allowFullScreen: true,
                name: this.props.id,
                style: _.assign(
                    this.getCommonStyle(),
                    this.getIframeDimensions(), {
                        display: shouldShowIframe ? 'block' : 'none',
                        position: 'absolute',
                        zIndex: this.state.ignoreAnchors ? 1001 : ''
                    }),
                title: widgetName,
                'aria-label': widgetName
            };

            if (this.shouldRenderIframeWithSrc()) {
                _.unset(iframeProperties, 'data-src');
                iframeProperties.src = iframeSrc;
            }

            return iframeProperties;
        },

        getSkinProperties() {
            const overlay = this._createOverlay(this.state.overlay);
            const compRootStyle = this.getCompRootStyle(this.props.style);
            const iframeProperties = this._getIframeProperties();

            let skinProps = {
                '': {
                    style: compRootStyle,
                    'data-ignore-anchors': this.state.ignoreAnchors,
                    'data-has-iframe': !!iframeProperties
                },
                overlay: this.state.showOverlay && this.isNotWorker() ? overlay : null,
                iframe: iframeProperties ? iframeProperties : 'remove'
            };

            this.checkIfNeedToSendMemberData();

            if (this.mutateSkinProperties) {
                skinProps = this.mutateSkinProperties(skinProps);
            }

            return skinProps;
        },

        _reload() {
            this._clearAliveTimeout();
            clearTimeout(this.showOverlyIntervalId);
            this.showOverlyIntervalId = setTimeout(() => {
                this._showOverlayIfNeeded();
            }, this.OVERLAY_GRACE);
            this._appAliveTimeout = setTimeout(this._onAppAliveTimeoutExpires, this.ALIVE_TIMEOUT);
            const appData = this.getAppData();
            this.props.reportBI(events.RELOAD_TPA_CLICK, {
                appDefinitionId: _.get(appData, 'appDefinitionId'),
                appName: _.get(appData, 'appDefinitionName')
            });

            this.registerReLayout();
            this.setState(this.getInitialState());
        },

        checkIfNeedToSendMemberData() {
            const siteMemberDetailsState = this.state.shouldGetSiteMemberDetails;
            if (siteMemberDetailsState) {
                const memberData = this.props.getMemberDetails();

                if (memberData) {
                    siteMemberDetailsState.callback({
                        authResponse: true,
                        data: memberData
                    });
                }
            }
        },

        isTPASupportedInSSRWarmup() {
            const appData = this.getAppData();
            return tpaWarmup.isTpaSupported(appData, this.getWidgetId(), this.props.isExperimentOpen, null);
        },

        getInitialState() { // eslint-disable-line complexity
            let biParams;
            if (!this.isNotWorker()) {
                biParams = {widgetName: 'worker'};
            }
            if (!this.props.isFirstRenderAfterSSR && this.shouldRenderIframeWithSrc()) {
                this.reportPerformanceBiEvent(tpaWarmup.events.APP_IFRAME_START_LOADING, biParams);
            }

            this.urlState = '';

            const underMobileAndNotSupported = this.isUnderMobileView() && this.isMobileReady && !this.isMobileReady();
            const overlay = underMobileAndNotSupported ? DENY_IFRAME_RENDERING_STATES.mobile : null;

            let initialState = {
                visibility: 'hidden',
                overlay,
                isAlive: false,
                registeredEvents: [],
                showOverlay: true,
                initialWidth: this.props.style.width,
                initialHeight: this.props.style.height,
                initialInstance: this.props.dynamicClientSpecMapAspect.getAppInstance(this.props.compData.applicationId) || this.getAppData(this).instance,
                initialRouterData: _.get(this.props.getExistingRootNavigationInfo(this.props.currentUrlPageId), 'routerPublicData'),
                externalId: this.props.compData.referenceId
            };

            if (this.isTPASupportedInSSRWarmup()) {
                _.assign(initialState, {
                    visibility: 'visible',
                    isAlive: true
                });
            }

            this.shouldUpdateInitialRouterData = true;

            if (this.mutateInitialState) {
                initialState = this.mutateInitialState(initialState);
            }
            return initialState;
        },

        isCompInClientSpecMap() {
            const clientSpecMap = _.get(this.props, 'clientSpecMap');
            const applicationId = _.get(this.props, 'compData.applicationId');
            if (!clientSpecMap || !applicationId) {
                return false;
            }
            const clientSpecMapEntry = _.get(clientSpecMap, applicationId);
            return !!clientSpecMapEntry;
        },

        _showOverlayIfNeeded() { // eslint-disable-line complexity
            const isCompInClientSpecMap = this.isCompInClientSpecMap();
            const isViewerMode = _.get(this.props, 'isViewerMode');
            if (this.state && this.state.isAlive === false && (!this.state.overlay && isCompInClientSpecMap || !isCompInClientSpecMap && !isViewerMode)) { // eslint-disable-line no-mixed-operators
                if (this._isMounted) {
                    this.setState({
                        overlay: this._getInitialOverlay(),
                        visibility: 'visible'
                    });
                }
            }
        },

        _shouldShowIframe(state) {
            if (_.get(this.props, 'compProp.isHidden')) {
                return false;
            }
            return !state.overlay || state.overlay === 'preloader';
        },

        _getInitialOverlay() {
            if (this.isMobileReady && this.isUnderMobileView() && !this.isMobileReady()) {
                return DENY_IFRAME_RENDERING_STATES.mobile;
            }

            /*  //server has regax to switch iframe to the site protocol so this use-case is not valid
            var isIframeUrlSecure = this._isUrlSecure(iframeUrl);
            var isSiteUrlSecure = this._isUrlSecure(siteUrl);
            var isUnmatchingSecureness = isSiteUrlSecure && !isIframeUrlSecure;
            return isUnmatchingSecureness ? DENY_IFRAME_RENDERING_STATES.https : 'preloader';
            */

            if (!this.isCompInClientSpecMap()) {
                return OVERLAY_STATES.notInClientSpecMap;
            }

            return OVERLAY_STATES.preloader;
        },

        componentSpecificShouldUpdate(nextProps) {
            return !nextProps.pageStub;
        },

        handleExternalIdStateUpdate(nextProps) {
            const shouldUpdateExternalId = !this.state.preventRefresh && this.state.externalId !== nextProps.compData.referenceId;
            if (shouldUpdateExternalId) {
                this.setState({
                    externalId: nextProps.compData.referenceId
                });
            }
        },

        componentWillReceiveProps(nextProps) {
            this.handleExternalIdStateUpdate(nextProps);
            const newRouterPublicData = _.get(this.props.getExistingRootNavigationInfo(nextProps.currentUrlPageId), 'routerPublicData');
            if (this.props.rootId === 'masterPage' && this.shouldUpdateInitialRouterData && !_.isUndefined(newRouterPublicData) && !_.isEqual(this.state.initialRouterData, newRouterPublicData)) {
                this.setState({
                    initialRouterData: newRouterPublicData
                });
                this.shouldUpdateInitialRouterData = false;
            }
            const isResizePreviewExtensionAvailable = this.resize;
            if (isResizePreviewExtensionAvailable) {
                this.resize(nextProps);
            }
            const oldInstance = _.get(this.props.clientSpecMap, [this.props.compData.applicationId, 'instance']);
            const newInstance = _.get(nextProps.clientSpecMap, [this.props.compData.applicationId, 'instance']);
            if (oldInstance !== newInstance) {
                this.setState({
                    initialInstance: newInstance
                });
            }
        },

        componentWillMount() {
            this.props.windowResizeEvent.registerToResize(this);
        },

        componentDidMount() { // eslint-disable-line complexity
            this._isMounted = true;
            if (this.props.shouldRenderTPAsIframe) {
                this.startTPAPendingPhase();
            }
        },

        startTPAPendingPhase() {
            const overlay = this.state.overlay;
            if (this.isNotWorker()) {
                this.showOverlyIntervalId = setTimeout(() => {
                    this._showOverlayIfNeeded();
                }, this.OVERLAY_GRACE);
                if (!this.props.isViewerMode) {
                    if (this.setOverlayState) {
                        this.setOverlayState();
                    }
                }
            }
            if (!(this.state.isAlive ||
                _.includes(DENY_IFRAME_RENDERING_STATES, overlay)) && this.isNotWorker()
            ) {
                this._appAliveTimeout = setTimeout(this._onAppAliveTimeoutExpires, this.ALIVE_TIMEOUT);
                tpaUtils.incAppCounter();
            }
        },

        isNotWorker() {
            if (this.isTPAWorker) {
                return !this.isTPAWorker();
            }
            return true;
        },

        _onAppAliveTimeoutExpires() {
            this.showUnresponsiveOverlay();
        },

        showUnresponsiveOverlay() {
            if (this._isMounted && !this.state.isAlive && this.isCompInClientSpecMap()) {
                this.setState({
                    overlay: OVERLAY_STATES.unresponsive,
                    visibility: 'visible'
                }, function () {
                    this.reportBIAppNotResponsive();
                }.bind(this));
            }
        },

        reportBIAppNotResponsive() {
            const widgetId = this.props.compData.widgetId;
            const appData = this.getAppData();
            const widgetData = _.get(appData.widgets, widgetId);
            const biData = this.props.biData;
            const reportParams = {
                'endpoint': _.get(widgetData, 'widgetUrl'),
                'app_id': _.get(appData, 'appDefinitionId'),
                'pageNo': biData.getPageNumber(),
                'totalLoadingTime': biData.getTime().totalLoadingTime
            };
            this.props.reportBI(tpaErrors.APP_IS_NOT_RESPONSIVE, reportParams);
        },

        componentWillUnmount() {
            this._isMounted = false;
            this._clearAliveTimeout();
            clearTimeout(this.showOverlyIntervalId);
        },

        _clearAliveTimeout() {
            if (this._appAliveTimeout) {
                window.clearTimeout(this._appAliveTimeout);
                this._appAliveTimeout = 0;

                if (this.props) {
                    tpaUtils.decAppCounter(this.props.reportBeatEvent, this.props.primaryPageId);
                }
            }
        },

        setAppIsAlive(callback) {
            if (!(this.props.isClientAfterSSR && this.props.isTpaRenderedInSsr)) {
                this.reportPerformanceBiEvent(tpaWarmup.events.APP_SDK_START_LOADING);
            }
            this._clearAliveTimeout();

            this.setState({
                isAlive: true,
                overlay: this.state.overlay === OVERLAY_STATES.preloader ? null : this.state.overlay
            }, function () {
                this.props.tpaComponentsDomAspect.markComponentIsAlive(this.props.id);
                if (callback) {
                    callback();
                }
            });
        },

        _isUrlSecure(url) {
            return /^https/.test(url);
        },

        getWidgetId() {
            return _.get(this.props, 'compData.widgetId');
        },

        reportPerformanceBiEvent(eventDescriptor, params) {
            const appData = this.getAppData();
            const widgetId = this.getWidgetId();
            tpaWarmup.reportPerformanceBiEvent(eventDescriptor, this.props.reportBI, this.props.biData, this.props.id, this.props.isTpaRenderedInSsr, appData, widgetId, params);
        },

        enterFullScreen(callback) {
            if (isFullScreenAppOnMobile(this.props)) {
                this.setState({isMobileFullScreenMode: true}, callback);
            }
        },

        exitFullScreen(callback) {
            this.setState({isMobileFullScreenMode: false}, callback);
        }
    };
});
